<template>
  <v-form
    ref="driverTypeForm"
    v-model="valid"
    lazy-validation>
    <v-container
      grid-list-xl>
      <v-layout
        fluid
        pt-0
        wrap>
        <v-flex xs12>
          <app-card
            :footer="true"
            heading="Driver type information"
            col-classes="xl12 lg12 md12 sm12 xs12"
            custom-classes="mb-0"
          >
            <div>
              <rotate-square2 v-if="loading" />
              <v-container
                v-else
                grid-list-xl>
                <v-layout
                  row
                  wrap>
                  <v-flex
                    xs12
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="driverType.title"
                      :rules="[v => Boolean(v) || 'Please provide a title']"
                      type="text"
                      label="Title"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="driverType.description"
                      type="text"
                      label="Description"
                    />
                  </v-flex>
                  <v-flex
                    xs6>
                    <v-select
                      v-if="brandList.length"
                      :items="brandList"
                      :rules="[v => Boolean(v) || 'Please provide a brand']"
                      v-model="driverType.brand"
                      item-value="id"
                      item-text="name"
                      label="Brand"
                    />
                  </v-flex>
                  <v-flex
                    xs6>
                    <v-select
                      v-if="driverTypeList.length"
                      :items="driverTypeList"
                      :rules="[v => Boolean(v) || 'Please provide a driver type']"
                      v-model="driverType.ridesFilter"
                      item-value="id"
                      item-text="title"
                      label="Driver types"
                      multiple
                    />
                  </v-flex>
                  <v-flex
                    xs6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="driverType.icon"
                      type="text"
                      label="Icon"
                    />
                  </v-flex>
                  <v-flex
                    xs6>
                    <InputHotKeyWrapper>
                      <v-text-field
                        :max-length="TEXT_FIELD_MAX_LENGTH"
                        v-model="driverType.price"
                        type="number"
                        label="Price"
                      />
                    </InputHotKeyWrapper>
                  </v-flex>
                  <v-flex
                    v-if="driverType.minHoursPerRide"
                    xs6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="driverType.minHoursPerRide.time"
                      type="time"
                      label="Ride time (min)"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
            <template slot="footer">
              <v-btn
                color="primary"
                class="mr-3"
                @click="saveFunction()"
              >Save</v-btn>
            </template>
          </app-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import { TEXT_FIELD_MAX_LENGTH } from '../../../../constants/common'
import CrudTable from '../../../../modules/admin/CrudTable.vue'
import Request from '../../../../helpers/request'
import Brands from '../../../../helpers/brands'

export default {
  components: { CrudTable },
  mixins: [Request, Brands],
  data () {
    return {
      valid: true,
      loading: true,
      data: [],
      TEXT_FIELD_MAX_LENGTH,
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {}
      },
      brandList: [],
      driverType: {},
      driverTypeList: [],
      firstLoadFinish: false
    }
  },
  watch: {
    'driverType.brand': {
      async handler (newValue) {
        if (this.firstLoadFinish) {
          const { data } = await this.request('GET', `/driver-types`, { params: { brands: newValue } })
          this.driverTypeList = data.data
          this.driverType.ridesFilter = []
        }
      },
      deep: true
    }
  },
  async beforeMount () {
    this.brandList = await this.fetchBrands()
    this.requestData()
  },
  methods: {
    async requestData () {
      await this.request('GET', `/driver-types/${this.$route.params.id}`, {}, ({ data }) => {
        this.driverType = { ...data, brand: data.brand.id, ridesFilter: data.ridesFilter.map(item => item.id) }
      }, (loader) => { this.loading = loader })

      const { data } = await this.request('GET', `/driver-types`, { params: { brands: this.driverType.brand } })
      this.driverTypeList = data.data
      this.firstLoadFinish = true
    },
    saveFunction () {
      if (this.$refs.driverTypeForm.validate()) {
        let body = {
          data: {
            'title': this.driverType.title,
            'description': this.driverType.description,
            'brand': this.driverType.brand,
            'icon': this.driverType.icon,
            'price': parseFloat(this.driverType.price),
            'minHoursPerRide': this.driverType.minHoursPerRide && this.driverType.minHoursPerRide.time,
            'ridesFilter': this.driverType.ridesFilter
          }
        }

        this.request('PATCH', `/driver-types/${this.driverType.id}`, body, ({ data }) => {
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Driver type has been changed'
          })

          this.$router.push('/tables/driver-types')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-preview {
    img {
      width: 124px;
      height: 124px;
      background-color: white;
      border: 1px solid #DDD;
      padding: 5px;
    }
}
</style>
